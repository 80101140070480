$(document).ready(function () {

    // fixed navigation
    function scrollHandler() {
        if (window.scrollY >= 10) {
            document.querySelector('.js-navigation').classList.add('fixed-nav-active');
        } else {
            document.querySelector('.js-navigation').classList.remove('fixed-nav-active');
        }
    }

    window.addEventListener('scroll', scrollHandler);


    // TOGGLE MENU
    $(".hambuger-icon").click(function () {
        $("nav").toggleClass('nav-active');
        $(".navigation__link:last-child").removeClass('button-primary');
    });

    $(".navigation__list li").click(function () {
        $("nav").removeClass('nav-active');
    });


    // soft scroll function
    var softscroll = function (clickelement) {
        clickelement.click(function (event) {
            var indexHref = $(this).attr("href");
            var indexHash = indexHref.split('#')[1];
            var $indexSelector = $("#" + indexHash);

            if ($indexSelector.length > 0) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $indexSelector.offset().top - 120
                }, 850);

                // close navigation after selection
                if (checkMobileState == "block") {
                    $('.mobile-nav').removeClass("act");
                    $('.nav-fixed').fadeOut(300);
                }
            }
        });
    }

    // softscroll elements (navigation, actionboxes ...)
    softscroll($('.softscroll'));

});

// Swiper Slider
const swiper = new Swiper('.swiper', {
    // Optional parameters
    loop: true,
    spaceBetween: 50,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
});